.generate-icon {
  width: 18px !important;
  height: 18px !important;
  margin-bottom: 9px !important;
  &.icon--background {
    background-image: url('../../../assets/icons/generate.svg');
  }

  &.icon--mask {
    mask-image: url('../../../assets/icons/generate.svg');
  }
}
