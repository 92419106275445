@import "src/theme";


.company-page {
    &__header {
        margin-top: -100px;

        @include down("sm") {
            margin-top: -120px;
        }
    }

    &__header &__logo {
        width: 100px;
        height: 100px;

        @include down("sm") {
            width: 75px;
            height: 75px;
        }
    }
}
