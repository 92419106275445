.puzzle {
    position: relative;
    max-width: 100%;
    &, &__captcha {
        width: 500px;
        min-height: 210px;
        max-width: 100%;
    }

    &__captcha {
        display: block;

        .panel {
            position: relative;
            display: block !important;
            max-height: 310px;
            bottom: 0 !important;

            .bg-img {
                 height: 100%;
            }

            .puzzleImg {
                height: 100%;
            }
        }

        .slider {
            font-weight: bold;
            line-height: 28px !important;
            transition: none;

            &:before {
                display: none;
            }
        }
    }
}
