.session-list {
  width: 100%;

  &__title {
    padding-left: 6px;
    margin-bottom: 4px !important;
  }

  &__container {
    max-height: calc(52.52px * 3);
    border-radius: 10px;

    &:hover {
      &::-webkit-scrollbar {
        display: block !important;
      }
    }

    &::-webkit-scrollbar {
      background-color: inherit;
      width: 5px;
      display: none;
      position: absolute;
      right: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: orange;
      cursor: pointer !important;
    }
  }

  &__table {
    color: var(--color) !important;
    background-color: var(--back-sidebar);

    .MuiTableCell-root {
      border-bottom: 1px solid var(--body-bg) !important;
    }
  }
}
