.terms-modal {
  .MuiPaper-root {
    max-width: 900px;
  }

  &__content {
    padding: 15px;
  }

  .header-right-menu {
    @media (max-width: 800px) {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}

.modal_language {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;

  @media (max-width: 430px) {
    font-size: 13px;
    margin-top: 3px;
  }
}

.active_language {
  font-weight: 700;
  color: var(--primary-color);
}

.doc_header_block {
  .icon-button {
    margin-right: 0;
  }

  @media (max-width: 500px) {
    & > div:first-child {
      width: 100%;
    }

    justify-content: flex-end !important;

    button {
      width: 100%;
      margin-right: 0;
    }
  }
}
.document-buttons {
  @media (max-width: 600px) {
    flex-direction: column !important;
    align-items: start !important;
    width: 100% !important;

    button {
      width: 100% !important;
      &:last-child {
        margin-top: 1rem;
      }
    }
  }
}
