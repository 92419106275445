.container-auth_select-country {
    display: flex;
    flex-direction: column;
    position: relative;

    &>span {
        margin-bottom: 8px;
        color: #9596A2;
        font-size: 16px;
    }

    .auth-country-select-option {
        display: flex;
        align-items: center;

        .flag {
            margin-right: 10px;
        }

        span {
            font-size: 16px;
            color: black;
        }
    }
}