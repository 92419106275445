$text-primary: #FF5F00;

$color-primary: $text-primary;
$color-warning: #FFC107;
$color-error: #EE1717;
$color-black: #000000;
$color-white: #FFFFFF;
$color-muted: #AAAAAA;

$bg-dark: #231f20;


:export {
    textPrimary: $text-primary;
}
