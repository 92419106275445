.select-translation {
    &__option {
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: solid black 2px;
        object-fit: cover;
    }

    &--dark &__icon {
        border-color: #FFFFFF;
    }

    &__label {
        padding-left: 12px;
    }

    &--dark &__label {
        color: #FFFFFF;
    }

    .MuiSvgIcon-root {
        color: rgba(0, 0, 0, 0.54);
    }

    &:not(&--expanded) .MuiSvgIcon-root {
        display: none;
    }

    &:not(&--expanded) .MuiSelect-select {
        padding-right: 0 !important;
    }

    &--dark .MuiSvgIcon-root {
        color: #FFFFFF
    }
}
