.helpcrunch-iframe-wrapper {
  display: none !important;
}

body[data-theme='light'] {
  background-color: #f9fafc !important;
}

body[data-theme='dark'] {
  background-color: #0c0c0c !important;
}
