.wrapper-phone-input {
  position: relative;

  .react-select {
    position: absolute;

    &__control {
      border-radius: 10px;
      position: static !important;
      box-shadow: none !important;
      border: none !important;
      cursor: pointer;
      background-color: var(--back-sidebar) !important;
      left: 4px;

      &:hover {
        background-color: rgb(255 95 21 / 25%);
      }
    }

    &__input,
    &__input:focus {
      border: none;
      outline: none !important;
      border-color: white !important;
      box-shadow: none;
    }

    &__menu {
      min-width: 320px;
      width: 100%;
      box-shadow: rgb(255 95 21 / 25%) 0 0 0 0.2rem !important;
      background-color: var(--back-sidebar);
    }

    &__indicators {
      margin-right: 2px;
    }

    &__option {
      cursor: pointer;
      transition: background-color 0.1s linear;
      color: var(--color);
      background-color: var(--back-sidebar);

      &--is-focused {
        background-color: rgb(255 95 21 / 25%) !important;
      }

      &--is-selected {
        font-weight: bold;
        background-color: rgb(255, 95, 0) !important;
      }

      &:hover {
        background-color: rgb(255 95 21 / 25%) !important;
      }
    }
  }

  .react-select__single-value {
    .MuiGrid-container {
      div:last-child {
        display: none;
      }
    }
  }

  .PhoneInputInput {
    outline: none;
    border: none;
    background-color: var(--back-sidebar);
    color: var(--color);
    padding: 14px 60px;
    border-radius: 10px;

    &:focus {
      box-shadow: rgb(255 95 21 / 25%) 0 0 0 0.2rem;
    }
  }

  .text-input__right-addons {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.number-disabled {
  .react-select__control {
    background-color: var(--player-background) !important;
    pointer-events: none;
  }

  input {
    &:disabled {
      background-color: var(--player-background);
      color: #aaa;
    }
  }
}
