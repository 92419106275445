@import 'src/theme';

.withdrawal {
  letter-spacing: -0.3px;
  margin-top: 3rem;
  flex-direction: column !important;

  &__head {
    max-width: 320px;
    width: 100%;
  }
  &__title {
    font-size: 26px;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 16px;
    color: #999;
    max-width: 340px;
    width: 100%;
    margin-top: 1rem;

    p {
      display: inline-flex;
      align-items: center;
      color: var(--primary-color);
      font-size: 18px;
      font-weight: 600;
      margin-left: 5px !important;
    }
  }
}

