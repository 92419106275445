.select {
  .react-select {
    width: 100%;
    height: 46.4px;
    border-radius: 10px;
    border: none;
    outline: none;

    &__control {
      border-radius: 10px;
      box-shadow: none !important;
      border: none !important;
      height: 100%;
      background: var(--back-sidebar) !important;
    }


    &__indicator {
      padding: 8px 0;

      &:last-child {
        padding: 8px 8px 8px 0;
      }
    }
    &__input-container, &__single-value {
      color: var(--color) !important
    }

    &__input,
    &__input:focus {
      border: none;
      outline: none !important;
      border-color: white !important;
      box-shadow: none;
      color: var(--color) !important;
    }

    &__menu {
      box-shadow: rgb(255 95 21 / 25%) 0 0 0 0.2rem !important;
    }

    &__indicators {
      margin-right: 2px;
    }

    &__option {
      cursor: pointer;
      transition: background-color 0.1s linear;
      background-color: var(--back-sidebar);

      &--is-focused {
        background-color: rgb(255 95 21 / 25%) !important;
      }

      &--is-selected {
        font-weight: bold;
        background-color: rgb(255, 95, 0) !important;
      }

      &:hover {
        background-color: rgb(255 95 21 / 25%) !important;
      }
    }
  }

  .react-select__control--is-disabled {
    background-color: var(--player-background) !important;
  }
  .react-select__single-value--is-disabled {
    color: #AAA !important;
  }
  .react-select__control--is-disabled .react-select__indicator > svg {
    display: none;
  }

  &__helper-text {
    position: absolute;
    padding-top: 2px;
    padding-left: 8px;
  }

  &--error &__helper-text {
    color: #ee1717 !important;
  }
}
