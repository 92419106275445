.block_code {
    margin-top: 100px;

    &__title {
        font-size: 16px;
        color: #9596A2;
    }

    &__text {
        color: #9596A2;
        font-size: 12px;
    }

    &__send {
        font-size: 12px;
        cursor: pointer;
        margin-top: 20px;
        color: var(--primary-color);

        &:hover {
            opacity: 0.8;
        }
    }
}

.block_timer {
    margin-top: 55px;
    font-size: 16px;

    div {
        color: #9596A2;
    }

    p {
        cursor: pointer;
        color: #FF154D;

        &:hover {
            opacity: 0.8;
        }
    }

    span {
        margin-top: 15px;
        color: #1D1D1F;
    }
}

.authButton {
    margin-top: 190px;

    button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-radius: 5px;
        color: white;
        background-color: #FF5F15;
        font-size: 20px;

        @media (max-width: 430px) {
            font-size: 14px;

        }
    }

    button:disabled {
        background-color: #9596A2;
        cursor: not-allowed;
    }
}

.other_content {
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-top: 10px;
    align-items: center;
    color: black;

    @media (max-width: 600px) {
        margin-top: 20px;
    }

    span {
        color: #FF5F15;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}