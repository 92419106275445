@import 'src/theme';

.button {
  display: inline-flex;
  align-items: center;
  color: $color-black !important;

  &--dark {
    color: $color-white !important;
  }

  &--bold {
    font-weight: bold;
  }

  &--full-width {
    width: 100%;
  }

  &--align-center {
    justify-content: center;
  }

  &--align-left {
    justify-content: flex-start;
  }

  &--align-right {
    justify-content: flex-end;
  }

  &--variant-text {
    color: var(--primary-color) !important;

    &:hover {
      opacity: 0.8;
    }
  }

  &--variant-text#{&}--color-primary {
    color: $text-primary !important;
  }

  &--variant-text#{&}--disabled {
    color: $color-muted !important;
  }

  &--variant-primary,
  &--variant-secondary {
    color: $color-white !important;
    line-height: 14px;
    border-radius: 12px;
  }


  &--variant-primary#{&}--size-large,
  &--variant-secondary#{&}--size-large {
    padding: 12px 72px;
  }

  &--variant-primary#{&}--size-medium,
  &--variant-secondary#{&}--size-medium {
    padding: 0 30px;
    height: 40px;
  }

  &--variant-primary#{&}--size-small,
  &--variant-secondary#{&}--size-small {
    font-size: 0.875rem;
    padding: 0 20px;
    border-radius: 5px;
    min-height: 25px;
  }

  &--variant-primary {
    background-color: $color-primary;
  }

  &--variant-primary#{&}--disabled {
    color: $color-muted !important;
    background-color: #e2e2ea !important;
  }

  &--variant-primary#{&}--dark#{&}--disabled {
    color: $color-muted !important;
    background-color: #09090b !important;
  }

  &--variant-secondary {
    color: white !important;
    line-height: inherit;
    background-color: #aaaaaa !important;
  }

  &--variant-secondary#{&}--dark {
    background-color: #09090b !important;
  }

  &__left-addons,
  &__right-addons {
    flex: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
  }

  &__left-addons {
    padding-right: 4px;
  }

  &--size-large &__left-addons {
    padding-right: 8px;
  }

  &__right-addons {
    padding-left: 4px;
  }

  &--size-large &__right-addons {
    padding-left: 8px;
  }

  &--color-dark {
    color: white !important;
    background-color: #09090b;
  }

  &--disabled {
    pointer-events: none;
    color: gray !important;
  }

  &--color-danger {
    color: white !important;
    background-color: red;
  }

  @media (min-width: 2200px) {
    &--variant-primary#{&}--size-small,
    &--variant-secondary#{&}--size-small {
      padding: 20px 40px !important;
      height: 50px !important;
      border-radius: 15px !important;
      span {
        font-size: 1.1rem !important;
      }
    }
  }
}
