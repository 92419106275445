.profile-page {
    &__header {
        color: rgb(255, 95, 0);
        font-weight: bold;
    }

    &__right-cell {
        padding-left: 50px;

        @media screen and (max-width: 1440px) {
            padding-left: 20px;
        }

        @media screen and (max-width: 1199px) {
            padding-left: 0;
        }
    }

    &__left-cell {
        padding-right: 50px;

        @media screen and (max-width: 1440px) {
            padding-right: 20px;
        }

        @media screen and (max-width: 1199px) {
            padding-right: 0;
        }
    }
}

.table-content-release {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:active {
        background: rgba(0, 0, 0, 0.9);
    }
}

.typography-two-factor {
    @media screen and (max-width: 700px) {
        font-size: 16px !important;
    }
}



@media screen and (max-width: 600px) {
    .profile-phone-close {
        .icon-button {
            width: 24px !important;
            height: 34px !important;
        }
    }

    .profile-facode-container {
        .fa-code-item {
            padding-left: 10px !important;
        }

        .fa-code__wrapper {
            padding-left: 10px !important;
        }
    }
}