.player {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  background-color: var(--player-background);
  border-radius: 20px;
  overflow: hidden;
  max-height: 80vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  @media screen and (max-width: 1024px) {
    flex-grow: unset;
  }

  @media screen and (max-width: 600px) {
    min-height: 200px;
  }

  .icon-container {
    @media (max-width: 400px) {
      padding-left: 7px !important;
    }
  }

  .MuiGrid-grid-xs-4 {
    &:first-child {
      .icon-container {
        &:first-child {
          @media (max-width: 400px) {
            padding-left: 20px !important;
          }
        }
      }
    }
  }

  .MuiGrid-grid-xs-4 {
    &:nth-child(2) {
      .icon-container {
        &:first-child {
          @media (max-width: 400px) {
            padding-left: 0 !important;
          }
        }
      }
    }
  }

  &__header {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 30px;
  }

  &:fullscreen &__header,
  &--fullscreen &__header {
    position: absolute;
    width: unset;
    color: #ffffff;
    top: 50px;
    left: 40px;
    right: 40px;
    z-index: 10;
  }

  &__swiper {
    width: 100%;
    height: 100%;
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: unset;
    background: none !important;
  }

  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    z-index: 1;
    background: #00000030;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @media (min-width: 1980px) {
      padding: 20px 40px;
    }

    @media (max-width: 600px) {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    @media (max-width: 440px) {
      padding: 5px 5px;
    }
  }

  &:fullscreen &__footer,
  &--fullscreen &__footer {
    left: 20px;
    right: 20px;
  }

  &--disable-controls &__footer {
    display: none;
  }
}

.volume-container {
  display: flex;
  align-items: center;
  padding-top: 10px !important;

  .volume-slider {
    transition: all 0.1s linear;
    opacity: 0;
    width: 0;
    height: 0;
    margin-left: 15px;
    align-items: center;
    display: flex;
    margin-top: 3px;
  }

  @media (screen and max-width: 768px) {
    .volume-slider {
      display: none;
    }
  }
  &:hover {
    .volume-slider {
      opacity: 1;
      width: 60px;
      height: 100%;
    }
  }
}

.player__slide {
  .player-item {
    display: none;
  }
}

.swiper-slide-active {
  .player-item {
    display: block;
  }
}

.mediaIcons {
  @media screen and (min-width: 1980px) {
    height: 35px;
    width: 35px;

    .icon {
      height: 25px;
      width: 25px;
    }
  }
}

.icon-wrapper {
  cursor: pointer;
  margin-top: 5px;
}
.mediaFullscreen {
  @media screen and (max-width: 575px) {
    display: none;
  }
}
