.date-group {
  &__helper-text {
    padding-top: 2px;
    padding-left: 8px;
    color: #ee1717 !important;
  }

  &__label {
    margin-bottom: 8px;
    color: #9596a2;
    font-size: 16px;
  }

  &__border {
    border: 1px solid #e2e2ea;
    height: 50px;
    align-items: center;
    margin-left: 0 !important;
    margin-top: 0 !important;
    border-radius: 4px;
    width: 100% !important;

    .select .react-select {
      background-color: white !important;

      &__control {
        background-color: white !important;
      }

      &__single-value {
        color: black !important;
      }

      &__menu {
        background-color: white !important;
      }

      &__option {
        background-color: white;
        color: black;
      }

      &__input {
        color: black !important;
      }
    }

    & > .MuiGrid-item {
      padding-top: 0 !important;
      border-right: 1px solid #e2e2ea;

      &:last-child {
        border-right: none !important;
      }
    }
  }
}

.date-group--error {
  .date-group__border {
    border: 1px solid red !important;

    & > .MuiGrid-item {
      padding-top: 0 !important;
      border-right: 1px solid red;
    }
  }
}

.auth-date-group {
  .field-label {
    margin-bottom: 3px !important;
    color: #9596a2 !important;
    font-size: 16px !important;
  }
}
