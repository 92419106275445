.player-seek {
    position: relative;
    margin-bottom: 10px;
    @media (min-width: 1980px) {
        margin-bottom: 20px;
    }
    &__track {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 4px;
        transform: translateY(-50%);
        background-color: #3e3e3e;
        @media (min-width: 1980px) {
            height: 6px;
            border-radius: 10px;
        }
    }

    &__fill, &__buffer, &__input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    &__fill {
        background-color: #ffffff;
        z-index: 2;
    }

    &__buffer {
        background-color: #5a5a5a;
        z-index: 1;
    }

    &__input {
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
