.btn_mailto {
  text-decoration: underline !important;
}

.accordion {
  border-radius: 10px !important;
}

.accordion-item:first-of-type,
.accordion-item:first-of-type button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.accordion-item {
  //background: var(--back-sidebar) !important;
}

.accordion-button {
  position: relative;
  &::after {
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    right: 20px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 0.1em solid var(--color);
    border-top: 0.1em solid var(--color);
    transform: rotate(-45deg);
    margin-right: 0.5em;
    margin-left: 1em;
    transition: all 0.2s linear !important;
    margin-top: 5px;
  }
}

.accordion-button:not(.collapsed)::before {
  border-right: 0.1em solid #ff5f00;
  border-top: 0.1em solid #ff5f00;
  transform: rotate(135deg);
  transition: all 0.2s linear !important;
  margin-top: 0;
}

.accordion-button,
.accordion-body {
  background: var(--back-sidebar) !important;
  color: var(--color) !important;
}

.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-item:last-of-type {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.accordion-title {
  text-transform: uppercase;
  color: #ff5f00;
  font-weight: bold;
}

.accordion-button:not(.collapsed) {
  color: #ff5f00 !important;
  background-color: #ffe3d3 !important;
}

.accordion-button:focus {
  border-color: #ff5f00 !important;
  box-shadow: 5px 5px 15px 5px rgba(255, 95, 0, 0.15) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('../../../assets/icons/down-arrow.png') !important;
}
