.auth-layout {
    position: relative;
    max-width: 1380px;
    padding: 30px 0;
    margin: 0 auto;
    background: #f0f1f7;

    .select-translation__label {
            color: black !important;
    }

    .select-translation--dark .MuiSvgIcon-root {
        color: gray !important;
    }


    @media (max-width: 1400px) {
        padding: 30px;
    }

    @media (max-width: 600px) {
        padding: 20px 15px 100px 15px;
    }

    .auth-content-container {
        @media (max-width: 900px) {
            justify-content: center !important;
        }
    }

    .auth-content {
        max-width: 550px;
        width: 100%;
        border-radius: 8px;
        height: fit-content;
        padding: 50px;
        background: white;
        box-shadow: 0px 0px 4px 0px rgba(226, 226, 234, 1);

        @media (max-width: 600px) {
            padding: 30px;
        }

        @media (max-width: 400px) {
            padding: 20px;
        }
    }

    .auth-logo {
        height: 50px;
    }

    .auth-right-content {
        max-width: 700px;
        width: 100%;

        @media (max-width: 900px) {
            display: none;
        }
    }

    .auth-right-content-row {
        display: flex;
        margin-left: 15px;

        @media (max-width: 1260px) {
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .auth-right-content-block {
        width: 300px;
        display: flex;
        padding: 35px 0;

        div {
            margin-right: 25px;
        }

        span {
            font-size: 14px;
            font-weight: 300;
            color: black;
        }
    }

    .auth-right-img {
        width: 330px;

    }
}