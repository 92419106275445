.face-api {
    display: flex;
    max-width: 100%;
    width: 600px;
    flex-direction: column;
    align-items: center;
}
.face-api__video {
    min-height: 50px;
    margin-bottom: 5px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #e2e2ea;
}

.face-api-loader{
    min-height: 100px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.15);
}

.face-api__circle{
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    width: 50%;
    padding-top: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.face-api__img {
    position: relative;
    display: flex;
    align-items: center;
}
.face-api__canvas {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.face-api-success-icon{
    color: #6daa50;
    font-size: 40px!important;
}
.face-api-success{
    padding: 40px 0;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.face-api-permission{
    padding: 30px 0;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}