.Toastify__toast-container {
    width: 450px !important;
}

.Toastify__toast {
    border-radius: 10px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: "Mont Blanc", -apple-system, BlinkMacSystemFont, "Segoe UI",
                 "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                 "Helvetica Neue", sans-serif;
}

.Toastify__toast--success {
    background-color: #6daa50 !important;
}

.Toastify__toast--error {
    background-color: #EE1717 !important;
}

.Toastify__toast--warning {
    background-color: #FF5F15 !important;
}

.Toastify__toast-icon {
    width: 25px;
    font-size: 30px !important;
    margin-inline-end: 36px !important;
}

.toastIcon {
    color: white;
    font-size: 30px !important;
}
