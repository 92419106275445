.calendar-container {
  position: relative;

  &__datepicker {
    border-radius: 10px;
    background-color: var(--back-sidebar);
  }
}

.date-picker-popover {
  box-shadow: none !important;
  border-radius: 10px !important;
  margin-top: 0.5rem !important;
  background: transparent !important;
  
  @media (max-width: 769px) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

.date-picker-root {
  @media (max-width: 769px) {
    .MuiBackdrop-root {
      background: black !important;
      opacity: 0.4 !important;
    }

  }
}

.Calendar__day,
.Calendar__header,
.Calendar__monthText,
.Calendar__yearText,
.Calendar__monthSelectorItemText,
.Calendar__yearSelectorText,
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  color: var(--color);
}

.Calendar__monthYear.-shown > *:hover,
.Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus,
.Calendar__monthYear > *.-activeBackground {
  background: var(--player-background);
}

.Calendar__monthSelectorItem:not(.-active)
.Calendar__monthSelectorItemText:not(:disabled):hover,
.Calendar__yearSelectorItem:not(.-active)
.Calendar__yearSelectorText:not(:disabled):hover {
  background: var(--player-background);
}

.Calendar__monthSelector,
.Calendar__yearSelector {
  background-color: var(--back-sidebar);
}

.Calendar__yearSelectorWrapper::after {
  background-image: linear-gradient(
                  to bottom,
                  var(--back-sidebar),
                  var(--back-sidebar) 10%,
                  rgba(245, 245, 245, 0)
  );
  top: -0.1em;
}

.Calendar__yearSelectorWrapper::before {
  background-image: linear-gradient(
                  to top,
                  var(--back-sidebar),
                  var(--back-sidebar) 10%,
                  rgba(245, 245, 245, 0)
  );
  bottom: 0;
}

body[data-theme='dark'] {
  .Calendar__monthArrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='%23FFFFFF'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  }
}

//.MuiPopover-paper {
//  box-shadow: none !important;
//  background-color: transparent !important;
//  margin-top: 1rem;
//}
