.player-item {
    position: relative;
    width: 100%;
    height: 100%;

    &__header {
        margin-bottom: 30px;
    }

    &:fullscreen &__header {
        position: absolute;
        top: 50px;
        left: 80px;
        right: 80px;
        color: #FFFFFF;
        z-index: 1;
    }

    &__title {
        margin-left: 30px;
    }

    &__category-title {
        margin-left: 30px;
    }

    &__video {
        position: relative;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        align-items: center;
        height: 100%;
        max-width: 100%;

        video {
            border-radius: 20px;
            max-height: 100%;
            height: 100%;
            max-width: 100%;
            background: #e4e5ee;

            @media (max-width: 600px) {
                border-radius: 2px;
            }
        }
    }

    &--fullscreen &__video {
        border-radius: 0;

        video {
            border-radius: 0;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
    }
    .player-item__play_container{
        background-color: rgba(90, 90, 90, 0.6);
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 600px){
            width: 60px;
            height: 60px;
        }
    }
    .player-item__play_icon{
        transform: translateX(4px);
        @media screen and (max-width: 600px){
            width: 35px;
            height: 35px;
        }
    }

    &__seek {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px 20px;
        z-index: 999;
    }

    &:fullscreen &__seek {
        left: 80px;
        right: 80px;
    }

    &__duration {
        color: #FFFFFF;
    }
}