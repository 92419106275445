.container_verify {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    color: #1D1D1F;
    text-align: center;

    div {
        margin: 65px 0;
    }

    span {
        font-size: 26px;
        font-weight: 700;
    }

    p {
        font-size: 20px;
    }
}