.player-volume-modal {
  position: absolute;
  left: 20px;
  bottom: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  border-radius: 10px;
  padding: 2px;
  background-color: rgba(29, 29, 31, 0.502);
  z-index: 100;

  &:not(&--open) {
    display: none;
  }

  &__slider {
    height: 4px !important;
    color: white !important;
  }

  &__rail {
    background-color: #ffffff !important;
  }
}
