.drawer {
  & > div:first-child {
  background: transparent !important;
  }

}

.paper {
  margin: 30px 0 !important;
  width: 25%;
  padding: 1rem !important;
  border-radius: 20px 0 0 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;

  background: var(--back-sidebar) !important;

  height: calc(100vh - 60px) !important;

  * {
    letter-spacing: -0.3px !important;
  }
}

.divider {
  height: 0 !important;
}

.video {
  border-radius: 10px;
  width: 100%;
}

.button {
  padding: 10px 18px !important;
  border-radius: 8px !important;
}

.title {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* количество строк */
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 310px;
  text-overflow: ellipsis;
}

@media (max-width: 769px) {
  .paper {
    width: 320px;

    margin-top: 20px !important;
    margin-bottom: 60px !important;

    height: calc(100vh - 80px) !important;

    box-shadow: none !important;

  }

  .drawer {
    & > div:first-child {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }

  }

  .title {
    max-width: 240px;
  }
}