.container-auth_text_field {
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    margin-bottom: 8px !important;
    color: #9596a2 !important;
    font-size: 16px !important;
  }

  input {
    border: 1px solid #e2e2ea;
    height: 50px;
    padding-left: 10px;
    border-radius: 4px;

    &:-internal-autofill-selected {
      background-color: white !important;
    }

    &:focus {
      border: 1px solid #ffe015;
      box-shadow: 0px 1px 4px 0px #ffe015;
    }
  }

  .input__icon {
    cursor: pointer;
    position: absolute;
    top: 47px;
    right: 15px;
    display: flex;
    align-items: center;
  }

  .error-input {
    padding-top: 2px;
    padding-left: 8px;
    color: #ee1717 !important;
    font-size: 0.7rem;
  }
}
