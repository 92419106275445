@import "src/theme";


.menu-item {
    display: flex;
    width: calc(100% - 8px);
    padding: 8px 15px 8px 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    align-content: center;
    align-items: center;
    height: 54px;
    color: $color-black;

    &--dark {
        color: $color-white;
    }

    &--active {
        border-radius: 10px;
        color: #FFFFFF !important;
        background: #FF5F15;
    }

    &__icon {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    &__label {
        margin-left: 12px !important;
        max-width: 177px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: 600px) {
            max-width: 100%;
        }

        @media (max-width: 400px) {
            max-width: 200px;
        }
    }
}


.privated-item {
    pointer-events: none;
    cursor: not-allowed;
}