.home-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
  @media screen and (max-width: 600px) {
    overflow: unset;
  }
  @media screen and (max-width: 878px) {
    height: unset;
  }
  &__captcha {
    z-index: 2 !important;
  }
}

.video__bottom_button {
  @media screen and (min-width: 2200px) {
    width: 250px!important;
  }
}
