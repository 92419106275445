.checkbox {
    display: flex;
    align-items: center;

    &__input {
        display: none;
    }

    &__box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border: 1px solid #FFFFFF;
        border-radius: 6px;
        background-color: #FFFFFF;

        .icon {
            width: 20px;
            height: 20px;
        }
    }

    &--error &__box {
        border-color: #EE1717;
    }

    &--checked &__box {
        color: #FFFFFF;
        border-color: orange;
        background-color: orange;
    }

    &__label {
        display: block;
        margin-left: 8px;
    }

    &--error &__label {
        color: #EE1717;
    }
}
