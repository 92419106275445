@import "src/theme";


.dialog {
    .MuiPaper-root {
        border-radius: 20px;
        background-color: #f0f1f7;
    }

    &--dark {
        .MuiPaper-root {
            color: $color-white;
            background-color: rgb(29, 29, 31);
        }
    }
}
