$breakpoints: (
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536
);


:export {
    @each $key, $value in $breakpoints {
        #{$key}: $value;
    }
}