.container {
  background-color: var(--main-content-bg);
}

.header {
  font-weight: 600;
  font-size: 20px;
  background-color: var(--back-sidebar) !important;

  button {
    border-radius: 50%;

    &:focus {
      box-shadow: none;
    }
  }
}