.pass_remember {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: #9596a2;
  font-size: 12px;

  &__forgot {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  div {
    &:first-child {
      transition: all 0.2s linear;
      display: flex;
      align-items: center;
      cursor: pointer;

      div {
        width: 22px;
        height: 14px;
        border-radius: 8px;
        border: 3px solid #9596a2;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 5px;
          top: 1px;
          left: 1px;
          background-color: #9596a2;
        }
      }

      span {
        margin-left: 15px;
      }
    }
  }
}

.active_remember {
  div {
    border: 3px solid #ff5f15 !important;

    &::before {
      background: #ff5f15 !important;
      left: 9px !important;
    }
  }
}

.checked_documents {
  font-size: 12px;
  display: flex;
  padding: 30px 0 0;

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    width: 20px;
    height: 18px;
    min-height: 18px;
    border-radius: 5px;
    border: 2px solid #e2e2ea;
    margin-right: 10px;
    cursor: pointer;
  }

  &__text {
    color: #707070;

    span {
      color: #ff5f15;
      cursor: pointer;
    }
  }
}

.checked_documents + .checked_documents {
  padding-top: 20px;
}
div[data-checked='true'] {
  border: 2px solid #ff5f15 !important;
}

.authButton {
  margin-top: 20px;
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 5px;
    color: white;
    background-color: #ff5f15;

    font-size: 20px;

    @media (max-width: 430px) {
      font-size: 14px;
    }
  }

  button:disabled {
    background-color: #9596a2;
    cursor: not-allowed;
  }
}

.other_content {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 14px;
  align-items: center;
  color: black;

  @media (max-width: 600px) {
    margin-top: 20px;
  }

  span {
    color: #ff5f15;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__demo {
    font-size: 18px;
    text-decoration: underline !important;
    text-underline-offset: 4px;
  }
}

.rightContent {
  max-width: 700px;
  padding: 20px;
  width: 100%;
  padding-top: 70px;

  &_row {
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-top: 70px;
    }

    &__block {
      display: flex;
      font-size: 14px;
      font-weight: 500;

      img {
        width: 330px;
        height: 480px;
        z-index: 1;
      }

      div {
        margin-right: 10px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
