.complaint-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    max-height: 100%;
    z-index: 100;

    &:not(&--open) {
        display: none;
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 550px;
        max-width: 100%;
        height: 400px;
        max-height: 100%;
        background-color: rgba(29, 29, 31, 0.85);
        border-radius: 20px;
        padding: 15px 10px;
        font-size: 18px !important;
    }

    &__content {
        flex: 1;
        height: unset;
        padding: 0 20px;
    }

    &__item {
        display: flex;
        align-items: center;
        color: white;
        padding: 15px 0;
        //margin: 30px 0;
    }

    &__input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        -webkit-transition: 223ms;
             -o-transition: 223ms;
                transition: 223ms;
        cursor: pointer;
        background-color: transparent;
    }

    &__item--checked &__input {
        border-color: var(--primary-color) !important;
        background-color: var(--primary-color) !important;
    }

    &__label {
        cursor: pointer;
        margin-left: 13px;
        color: white;
    }

    &__item--checked &__label {
        color: var(--primary-color) !important;
    }

    &__footer {
        padding-top: 15px;
    }
}

.collapse_hasChild {
    padding: 0px 20px;
}