.file-dnd {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    background-color: #FFFFFF;

    &--variant-large {
        height: 250px;
    }

    &__wrapper {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        border: 2px dashed #FFFFFF;
        border-radius: 10px;
        transition: border .5s;
    }

    &--variant-large &__wrapper {
        //flex-direction: column;
        border-color: #e3e3eb;
    }

    &__drop {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__label {
        color: #000000;
        font-size: 14px;
        text-align: center;
    }

    &--dark &__label {

    }

    &--dragging &__wrapper {
        border-color: orange;
    }

    &__preview {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        background-color: #e3e3eb;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__left-addons, &__right-addons {
        display: flex;
        align-items: center;
        flex-basis: 50px;
    }

    &__left-addons {
        justify-content: right;
    }

    &__right-addons {
        justify-content: left;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__file {

    }

    //&--filled &__preview {
    //    flex: 1;
    //    display: flex;
    //    padding: 8px;
    //}

    &__image {
        width: 56px;
    }

    &__info {
        padding: 5px 10px;
        font-style: italic;
    }

    &__clear {
        //position: absolute;
        margin-top: -8px;
        margin-left: -8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        cursor: pointer;
        background-color: #e3e3eb;
        z-index: 2;

        &:hover {
            background-color: orange;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 0;
    }

    &--filled &__input {
        display: none;
    }
}
