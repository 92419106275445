.switch-theme {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        -webkit-transition: .4s;
        transition: .4s;
        border: solid black 2px;
        border-radius: 34px;

        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            top: 2px;
            left: 2px;
            bottom: 2px;
            background-color: black;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../../icons/LightModeIcon/images/sunny.svg");
        }
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &--dark &__slider {
        border: solid white 2px;
        background-color: transparent;

        &:before {
            background-color: white;
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
            background-image: url("../../icons/DarkModeIcon/images/moon.svg");
        }
    }
}
