.message_password {
  font-size: 0.7rem;
  padding-top: 2px;
  padding-left: 8px;
}

.checked_documents {
  font-size: 12px;
  display: flex;
  padding: 30px 0 0;

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    width: 20px;
    height: 18px;
    min-height: 18px;
    border-radius: 5px;
    border: 2px solid #e2e2ea;
    margin-right: 10px;
    cursor: pointer;
  }

  &__text {
    color: #707070;

    span {
      color: #ff5f15;
      cursor: pointer;
    }
  }
}
.checked_documents+.checked_documents{
  padding: 20px 0 0;
}

div[data-checked='true'] {
  border: 2px solid #ff5f15 !important;
}

.authButton {
  margin-top: 20px;
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 5px;
    color: white;
    background-color: #ff5f15;
    font-size: 20px;

    @media (max-width: 430px) {
      font-size: 14px;
    }
  }

  button:disabled {
    background-color: #9596a2;
    cursor: not-allowed;
  }
}

.other_content {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 14px;
  align-items: center;
  color: black;

  @media (max-width: 600px) {
    margin-top: 20px;
  }

  span {
    color: #ff5f15;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.date-group__border {
  .MuiGrid-item {
    .select .react-select {
      background-color: white !important;

      &__control {
        background-color: white !important;
      }
    }
  }
}
