.filters {
  &__item {
    margin-right: 1rem !important;
    margin-top: 1rem !important;

    &:last-child {
      display: none;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
    @media (max-width: 769px) {
      margin-right: 0 !important;
      &__item {
          margin-right: 0 !important;
        width: 100%;

        &:last-child {
          width: 100%;
          background: var(--back-sidebar) !important;
          border-radius: 10px !important;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          justify-content: center;
          height: 46.4px;

          button {
            width: 100% !important;
            border-radius: 10px !important;
          }
        }

        &:not(:first-child):not(:last-child) {
         display: none;
        }
        &_active {
          width: 100%;
          display: block !important;
        }
      }
    }
}