.authButton {
    button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-radius: 5px;
        color: white;
        background-color: #FF5F15;

        font-size: 20px;

        @media (max-width: 430px) {
            font-size: 14px;

        }
    }

    button:disabled {
        background-color: #9596A2;
        cursor: not-allowed;
    }
}