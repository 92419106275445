.dashboard-header {
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #FFFFFF;

    &--dark {
        background-color: #231f20;
    }
}
