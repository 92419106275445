.notifications_wrapper {
  .label {
    width: 100%;
    font-family: 'Mont Blanc', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 4px;
    padding-left: 6px;

    @media screen and (min-width: 1680px) {
      max-width: unset;
    }
  }

  .notofications_label {
    margin-bottom: 13px;

    span {
      background-color: #d6d6d6;
      border-radius: 5em;
      display: inline-block;
      font-size: 0.8em;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 1.2em;
      margin-left: 0.5em;
      padding: 0.25em 0.5em;
    }
  }

  .open {
    &::before {
      transform: rotate(-45deg) !important;
      top: 0.625em !important;
    }
  }

  .contentNotifications {
    background-color: var(--back-sidebar);
    border-radius: 10px;
    margin-bottom: 1em;

    &__row {
      letter-spacing: -0.4px;
      padding: 0.2em 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--body-bg);

      div {
        width: 100%;
        @media screen and (max-width: 430px) {
          font-size: 12px;
        }

        &:not(:first-child) {
          display: flex;
          justify-content: center;
        }
      }

      &:first-child {
        div {
          font-weight: 600;

          @media screen and (max-width: 430px) {
            div {
              font-weight: 500;
            }
          }

          &:first-child {
            padding: 0;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .slider {
    cursor: pointer;
    backface-visibility: hidden;
    height: 32px;
    position: relative;
    width: 57px;

    input {
      opacity: 0;
      height: 100%;
      margin-right: 0;
      width: 100%;
    }

    span {
      background-color: #141414;
      cursor: pointer;
      border-radius: 32px;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 0.4s;

      &::before {
        background-color: #ffffff;
        transform: translateX(0);
        background-color: #ffffff;
        border-radius: 50%;
        bottom: 4px;
        content: '';
        height: 24px;
        left: 4px;
        position: absolute;
        transition: transform 0.4s;
        width: 24px;
      }
    }
  }

  .checked {
    span {
      background-color: var(--primary-color) !important;

      &::before {
        transform: translateX(26px) !important;
      }
    }
  }

  .notification_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5em 1em;
    color: #141414;
    font-weight: 600;
    // border: 1px solid #d6d6d6;
    border-radius: 10px;
    margin-bottom: 0.3em;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      font-size: 18px;
    }

    &__rightBlock {
      display: flex;
      align-items: center;

      h3 {
        margin-right: 10px;
        font-size: 14px;
        margin: 0 10px 0 0 !important;
        color: #14141460;
      }
    }
  }
}
