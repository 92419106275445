@import 'src/theme';

.transactions, .transactions-table {
  max-width: 540px;
  width: 100%;
}
.transactions-table {
  &__scroll {
    max-height: 410px;
    padding: 0 10px;
  }
}

.transactions-action {
  flex-wrap: nowrap !important;
  align-items: center;
    @media (max-width: 601px) {
      flex-wrap: wrap !important;
      flex-direction: column-reverse !important;
    }
  &__item {
    flex-wrap: nowrap !important;
    margin-right: 8px !important;

    @media (max-width: 601px) {
      width: 100% !important;
      margin-right: 0 !important;
      margin-top: 8px !important;
    }
    &:last-child {
      margin-right: 0;
      width: 80% !important;
      @media (max-width: 601px) {
        width: 100% !important;
      }
      &> div {
        &:first-child {
          width: 100%;
          margin-right: 8px;
        }
      }
    }


    &:last-child {
      margin-right: 0 !important;
      width: auto;
    }
    &_button {
      background: var(--back-sidebar) !important;
      height: 46px;
      border-radius: 10px !important;
      padding: 0 11px !important;
    }
  }
}

