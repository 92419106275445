.wrapper {
  background-color: var(--grey-color);
  height: 100%;
  /*padding-top: 50px;*/
  margin-top: 50px;
}

.info {
  margin-bottom: 15px;
}

.profile-icon--worst {
  width: 65px;
  border-radius: 50%;
  height: 65px;
  object-fit: cover;
  margin-right: 15px;
}

/* new */
.videoWrapper {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
}

.video {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.video__player {
  width: 100%;
  height: 100%;
  object-fit: fill;
  scroll-snap-align: start;
}

.video > div:nth-child(1) > div:nth-child(1) {
  display: none !important;
}


.history-page-heading {
  color: #ff5f15;
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 30px;
}

.not-video-title {
  font-family: 'Mont Blanc', sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 25px;
}


@media screen and (max-width: 600px) {
  .container-padding-none {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
