.reminder-container {
  display: flex;
  background-color: #cbcbcb;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .reminder-container {
    width: 34px;
    height: 34px;
  }
}

.reminder-container:hover {
  box-shadow: rgba(255, 95, 21, 0.5) 0 0 0 0.2rem !important;
}

.reminder-notification-alert {
  position: absolute;
  background-color: rgb(255, 55, 21);
  z-index: 50;
  right: 0;
  top: 0;
  text-align: center;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  color: white;
  line-height: 10px;
  font-size: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.reminder-notifications {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--opos-color);
  border-radius: 10px;
  top: 101%;
  left: -58px;
  width: 140px;
  z-index: 500;
}

.reminder-notifications::before {
  position: absolute;
  content: '';
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
  border-bottom: 17px solid var(--opos-color);
  display: block;
  width: 0;
  height: 0;
}

.notificationDropDownContainer {
  background-color: var(--body-bg);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 200px;
  text-align: center;
  position: relative;
  padding: 24px 24px 14px;
}

.notificationOverlay {
  z-index: 10000;
}

.notificationOverlay>.MuiBackdrop-root {
  background-color: rgb(0, 0, 0);
  opacity: 0.3 !important;
}

.notificationItem {
  font-size: 16px;
  font-weight: 600;
  color: var(--color);
}

.notificationItem+.notificationItem {
  margin-top: 17px;
  padding-top: 17px;
  border-top: 1px solid #707070;
}