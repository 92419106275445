@import "src/theme";


.fa-code {
    &__input {
        height: 50px;
        width: 50px;
        text-align: center;
        border: none;
        border-radius: 10px;
        background-color: white;

        &::placeholder {
            color: $color-muted;
        }

        &:disabled {
            color: $color-muted;
            background-color: #EAEAEA;
        }
    }
}
