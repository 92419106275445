/* main colors */

:root {
  --primary-color: #fa8200;
  --grey-color: #f1f1f1;
  --secondary-color: #f5b96d;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: var(--body-bg) !important;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

h1 {
  font-size: 20px !important;
}

h2 {
  font-size: 1.8rem;
}

h4,
p {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  outline: none !important;
  color: inherit !important;
}

img,
video {
  display: block;
}

*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

input {
  outline: none;
  background-color: transparent;
}

button,
select,
a {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
}

.icon-small {
  width: 25px;
  fill: #000000;
  -webkit-transition: fill 223ms;
  -o-transition: fill 223ms;
  transition: fill 223ms;
  cursor: pointer;
}

.icon-small-active {
  width: 25px;
  fill: var(--primary-color);
  -webkit-transition: fill 223ms;
  -o-transition: fill 223ms;
  transition: fill 223ms;
  cursor: pointer;
}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-align-start {
  display: flex;
  flex-direction: column;
}

.flex-row-center-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center-justify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
  display: flex;
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  background: none !important;
  border: none;
}

.btn-success:hover {
  background: none !important;
  border: none !important;
}

.btn-success {
  background: none !important;
  border: none !important;
}

.btn-success:focus {
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color) !important;
}

.burger-dropdown-menu {
  /*  */
  top: 46px !important;
  left: -165px !important;
}

.user-dropdown-menu {
  top: 55px !important;
  left: -136px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
}

.modal-content {
  border: none !important;
  width: 66% !important;
  margin: auto !important;
  /* border-radius: 20px !important; */
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.modal-footer {
  border-top: none !important;
  justify-content: space-between !important;
}

.btn-simple {
  border: none !important;
  background: none !important;
}

.btn-simple:focus {
  outline: none !important;
  box-shadow: none !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #fa8200 !important;
}

/* .MuiTabs-flexContainer {
    justify-content: center !important;
} */

.btn-prime {
  background: linear-gradient(95deg, #f8ad1a, #ea5e1f) !important;
  border-radius: 50px !important;
}

.rh5v-DefaultPlayer_controls {
  background: none;
}

.rh5v-Overlay_component {
  z-index: 2 !important;
}

.rh5v-Overlay_inner {
  background: url('../assets/icons/play-video.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 100px;
}

.rh5v-Overlay_icon {
  display: none;
}

.rh5v-Overlay_inner::after {
  content: '';
  width: 100px;
  height: 100px;
  position: absolute;
  left: -50%;
}

.rh5v-DefaultPlayer_component,
.rh5v-DefaultPlayer_video {
  border-radius: 10px;
}

/* .rh5v-DefaultPlayer_video {
    width: auto !important;
} */

.rh5v-DefaultPlayer_component {
  background: none !important;
  position: relative;
}

.rh5v-Volume_icon {
  padding: 0 !important;
  /* fill: #000; */
  stroke: #000;
  width: 20px;
  height: 20px;
}

.rh5v-Volume_button:hover .rh5v-Volume_slider {
  display: none !important;
}

.rh5v-Volume_slider {
  display: none !important;
  /* position: absolute;
    left: 50px;
    top: 20px;
    height: 56px;
    background-color: #000;
    transform: rotate(90deg); */
}

.rh5v-DefaultPlayer_component:hover .rh5v-DefaultPlayer_controls {
  opacity: 1 !important;
}

.rh5v-Time_component {
  color: white;
  font-size: 14px;
}

.rh5v-Seek_component,
.rh5v-PlayPause_component,
.rh5v-Time_component,
.rh5v-Volume_component {
  display: flex;
  align-items: flex-start;
  margin-left: 14px;
}

.rh5v-Seek_component,
.rh5v-Seek_track,
.rh5v-Seek_input {
  width: 100%;
}

.rh5v-Seek_input {
  cursor: pointer;
}

.rh5v-Volume_component {
  margin-right: 10px;
}

.rh5v-DefaultPlayer_controls {
  opacity: 1 !important;
  background-color: transparent !important;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 5px;
}

.tab-register,
.tab-login {
  width: 50%;
}

.tab-forgot {
  width: 100%;
}

.css-heg063-MuiTabs-flexContainer {
  justify-content: center !important;
}

@media screen and (min-device-width: 1200px) {
  .rh5v-DefaultPlayer_video {
    /* height: 400px !important; */
    width: 100% !important;
  }
}

/* .rh5v-PlayPause_component:hover {} */

/* //swiper */

.swiper {
  /*width: 100%;*/
  /*height: 100%;*/
}

.swiper-slide {
  /*width: 100% !important;*/
  /* margin-right: 50px; */
  /* padding: 100px; */

  /*text-align: center;*/
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  /*display: -webkit-box;*/
  /*display: -ms-flexbox;*/
  /*display: -webkit-flex;*/
  /*display: flex;*/
  /*-webkit-box-pack: center;*/
  /*-ms-flex-pack: center;*/
  /*-webkit-justify-content: center;*/
  /*justify-content: center;*/
  /*-webkit-box-align: center;*/
  /*-ms-flex-align: center;*/
  /*-webkit-align-items: center;*/
  /*align-items: center;*/
}

/*.swiper-slide {*/
/*  background: none;*/
/*}*/

.text-left {
  text-align: left;
}

.MuiOutlinedInput-input {
  padding: 8px 20px !important;
}

.MuiInputLabel-formControl {
  position: absolute;
  left: 0 !important;
  top: -42px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #000 !important;
}

.MuiInputLabel-root {
  color: #000 !important;
}

.MuiFormControl-marginNormal {
  margin-top: 26px !important;
  margin-bottom: 10px !important;
}

.navbar-expand {
  z-index: 2 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary-color) !important;
  border-radius: 0 50px 50px 0 !important;
  margin-left: -81px !important;
  padding-left: 95px !important;
  color: #fff !important;
}

.nav-link {
  color: #000 !important;
}

/*.recharts-wrapper {
  margin: auto !important;
  padding-top: 100px !important;
}*/

.leftBar {
  margin-top: 100px !important;
}

@media screen and (max-width: 600px) {
  .leftBar {
    margin-top: 0;
  }

  .rh5v-DefaultPlayer_controls {
    background: none !important;
  }

  .modal-content {
    width: 90% !important;
  }
}

video::-webkit-media-controls {
  display: none !important;
}

/* Scrollbar */

.scrollBar {
  height: calc(100vh - 180px) !important;
}

.scrollBar .ps__rail-y {
  display: none !important;
}

/* Scrollbar rail styles */

.ps__rail-y {
  width: 5px !important;
  /*right: 1% !important;*/
  border-radius: 6px;
}

@media (min-width: 1600px) {
  .ps__rail-y {
    /*right: 8% !important;*/
  }
}

.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-y,
.ps--scrolling-y > .ps__rail-y {
  opacity: 1 !important;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
  background-color: #d5d5d5 !important;
  opacity: 1 !important;
}

/* Scrollbar thumb styles/ */

.ps__thumb-y {
  background-color: var(--primary-color) !important;
  width: 5px !important;
  right: 0 !important;
  cursor: pointer;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: var(--primary-color) !important;
  width: 11px;
}

@media screen and (max-width: 600px) {
  .row-col-sm {
    flex-direction: column;
  }

  .margin-left {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .margin-left {
    margin-left: 24%;
  }
}

.accordion {
  border: none !important;
}

.accordion__button {
  background: none !important;
}

.accordion__button:before {
  display: none !important;
}

.accordion__heading {
  display: flex;
}

.accordion__heading:after {
  display: inline-block;
  color: orange;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.auth-form-title {
  font-size: 1.75rem;
  color: black;
}

@media (max-width: 600px) {
  .auth-form-title {
    color: black;
    font-size: 1.4rem !important;
  }
}

.underline {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
  text-underline-offset: 3px !important;
}

.field-label {
  position: relative;
  font-size: 1rem;
  padding: 5px 6px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.field-label--required::after {
  content: '*';
  color: red;
  font-size: 1.5rem;
  line-height: 0;
  position: absolute;
  top: 1.2rem;
  margin-left: 5px;
}
