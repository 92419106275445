@import 'src/theme';

.text-input {
  &--full-width {
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &--variant-outlined &__wrapper {
    border-bottom: 0.1rem solid $color-black;
  }

  &--variant-outlined#{&}--dark &__wrapper {
    border-bottom: 0.1rem solid $color-white;
  }

  &--variant-filled &__wrapper {
    border: 0.2rem solid var(--back-sidebar);
    border-radius: 10px;
    background-color: var(--back-sidebar);
  }

  &--variant-filled#{&}--disabled &__wrapper {
    border-color: var(--disabled);
    background-color: var(--disabled);
    cursor: not-allowed;
  }

  &--variant-filled#{&}--focus &__wrapper {
    box-shadow: rgb(255 95 21 / 25%) 0 0 0 0.2rem !important;
  }

  &--error &__wrapper {
    border-color: $color-error !important;
  }

  &--error-border &__wrapper {
    border-color: var(--back-sidebar) !important;
  }

  &__helper-text {
    position: absolute;
    padding-top: 2px;
    padding-left: 8px;
  }

  &--error &__helper-text {
    color: $color-error !important;
  }

  &__input {
    display: block;
    width: 100%;
    min-width: 0;
    height: 40px;
    padding: 8px 8px;
    border: none;

    &,
    &:active,
    &:focus {
      outline: none;
      -webkit-appearance: none;
    }

    &:invalid {
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }

    &::-webkit-input-placeholder {
      text-indent: 0;
    }

    &::-moz-placeholder {
      opacity: 1;
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: $color-muted;
    }
  }

  &--variant-outlined#{&}--dark &__input {
    color: var(--color);
  }

  &--variant-filled &__input {
    color: var(--color);
  }

  &--variant-filled#{&}--disabled &__input {
    color: $color-muted;
    cursor: not-allowed;
  }

  &__left-addons,
  &__right-addons {
    display: flex;
    flex: 0;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;

    & > * {
      margin-right: 4px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__left-addons {
    padding-right: 4px;
  }

  &__right-addons {
    padding-right: 5px;
  }

  &--variant-filled &__left-addons,
  &--variant-filled &__right-addons {
    color: $color-black;
  }
}
