.wallet-content {
  flex-wrap: nowrap !important;
  max-width: 1440px !important;
  width: 100%;
  margin: 0 auto;

  * {
    letter-spacing: -0.3px !important;
  }

  &__left {
    display: flex;
    margin-right: 3rem !important;
    max-width: 370px;
    width: 100%;
    
  }

  @media (max-width: 1025px) {
    flex-wrap: wrap !important;
    &__left {
      margin-right: 0 !important;
      width: 100%;
    }

    .withdrawal__subtitle {
      max-width: 100% !important;
    }

    .transactions {
      margin-top: 3rem;
    }
  }
    @media (max-width: 601px) {
      &__left, .transactions {
      max-width: none;
      }
    }
}
