.auth-arrow-back-icon {
    &.icon--mask {
        color: #FF5F15;
        mask-image: url("../../../assets/icons/auth_back_arrow.svg");
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    &.icon--background {
        color: #FF5F15;
        background-image: url("../../../assets/icons/auth_back_arrow.svg");
    }
}