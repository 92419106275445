.player-quality-modal {
    position: absolute;
    right: 55px;
    bottom: 65px;
    border-radius: 10px;
    padding: 15px 35px;
    background-color: rgba(29, 29, 31, 0.8);
    z-index: 100;

    &:not(&--open) {
        display: none;
    }

    .quality-text {
        transition: color 0.2s linear;

        &:hover {
            color: var(--secondary-color);
        }

        cursor: pointer;
    }
}