.radio-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__input {
        display: none;
    }

    &__circle {
        position: relative;
        flex-basis: 20px;
        width: 20px;
        height: 20px;
        //display: inline-block;
        //visibility: visible;
        border: 2px solid white;
        border-radius: 50%;
        -webkit-transition: 223ms;
             -o-transition: 223ms;
                transition: 223ms;
        background-color: transparent;
    }

    &--checked &__circle:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #ffffff;
    }

    &__label {
        flex: 1;
        padding-left: 10px;
    }
}
