.tooltiptext {
    position: absolute;
    right: 0;
    background-color: var(--primary-color);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 12px;
    bottom: -40px;
    position: absolute;
    z-index: 1;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 15px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primary-color) transparent;
}