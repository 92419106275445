@import 'src/theme';

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 75px;
  height: 200px;
  width: 200px;

  @media screen and (max-width: 430px) {
    width: 165px;
  }

  &__icon-wrapper {
    background: $color-white;
    border-radius: 15px;
    min-height: 120px;
    width: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--dark &__icon-wrapper {
    background-color: $color-black;
  }

  &__icon {
    svg {
      fill: var(--color);
      max-width: 60px;
      max-height: 60px;
      min-width: 60px;
    }
  }

  &__icon-border {
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid 1px $color-white;
    border-radius: 15px;
    transform: rotateZ(15deg);
  }

  &--dark &__icon-border {
    border-color: $color-black;
  }

  &__icon-title {
    font-family: 'Mont Blanc', sans-serif;
    font-size: 40px;
    color: $text-primary;
  }

  &__title {
    display: flex;
    min-height: 50px;
    align-items: center;
    margin-top: 15px;
    font-family: 'Mont Blanc', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 0 5px;
    //width: 130px;
    //max-width: 130px;
    word-break: break-word;
  }

  &__btn {
    margin-top: auto;
    width: 100%;
    min-height: 50px !important;
    line-height: 18px;
  }

  @media (min-width: 2200px) {
    &__btn {
      width: 100%;
      span {
        line-height: 22px;
      }
    }
  }
}
