@import "src/theme";


.divider {
    &--color-white {
        background-color: $color-white;
    }

    &--color-primary#{&}--light {
        background-color: $color-white;
    }

    &--color-primary#{&}--dark {
        background-color: $color-primary;
    }

    &--color-secondary#{&}--light {

    }
}
