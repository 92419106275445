@import 'src/theme';

.page-header {
  &--variant-primary &__title {
    margin-bottom: 1.5rem;
    color: $text-primary;
  }

  .container_headnav {
    flex-wrap: nowrap;

    @media (max-width: 1140px) {
      flex-wrap: wrap-reverse;
    }

    .container_headnav-grid {
      max-width: 100%;
    }

    @media (max-width: 600px) {
      .container_headnav-grid {
        flex-basis: auto !important;
      }
    }
  }

  .container_headnavigate {
    flex-wrap: nowrap;

    a {
      color: white !important;
      font-size: 1rem !important;
      font-weight: bold !important;
    }

    @media (max-width: 800px) {
      flex-wrap: wrap-reverse;

      p {
        text-align: center;
      }
    }
  }

  .container_wartext {
    @media (max-width: 1460px) {
      margin-right: 20px;
    }

    @media (max-width: 800px) {
      margin-right: 0;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }

  &__profile-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      width: 34px !important;
      height: 34px !important;
    }

    &:hover {
      box-shadow: rgba(255, 95, 21, 0.5) 0 0 0 0.2rem !important;
    }
  }
}

.profile-container {
  position: relative;

  div {
    background-color: #cbcbcb;
    color: var(--color);
    font-weight: 600;
  }
}

body[data-theme='dark'] {
  .profile-container {
    div {
      background-color: #353535;
    }
  }
}

.block-item {
  margin-left: 10px !important;
}

.userInfo {
  padding: 0 13px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.container-infouser {
  @media (max-width: 600px) {
    display: none;
  }
}

.userName {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  white-space: nowrap;
}

.userPhone {
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  text-align: end;
}

.setting-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cbcbcb;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

body[data-theme='dark'] {
  .setting-container,
  .reminder-container {
    background-color: #353535;
  }
}

.setting-container:hover {
  box-shadow: rgba(255, 95, 21, 0.5) 0 0 0 0.2rem !important;
}

.setting-label {
  position: absolute;
  top: -10px;
  right: -3px;

  svg {
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 3px;
    fill: white;
  }
}
