@import "src/theme";


$sidebar-width: 280px;

.dashboard-layout {
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;
    height: 100vh;
    background-color: var(--main-content-bg) !important;

    &__header {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 1001;

        @include down("sm") {
            display: flex;
        }
    }

    &__sidebar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        flex-basis: 95px;
        width: 95px;
        transition: flex-basis 0.1s linear,
            left 0.1s linear,
            width 0.1s linear;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100%;
        overflow: hidden;
        z-index: 11 !important;

        .sidebar {
            display: flex;
            flex-direction: column;
            padding-left: 15px;
            padding-right: 15px;
            height: 100%;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            background-color: #FFFFFF;

            &--dark {
                background-color: #231f20;
            }

            &__logo {
                width: 100%;
                height: 48px;
                background-position: left;
            }

            &__title {
                padding-left: 8px;
            }

            &__header {
                padding: 20px 0 15px 6px;

                @media (max-width: 600px) {
                    display: none;
                }
            }

            &__wrapper {
                flex: 1;
                height: unset;

                @media (max-width: 600px) {
                    margin-top: 24px;
                }
            }

            &__content {
                padding: 15px
            }

            &__footer {
                padding: 15px 15px 25px 15px;
            }

            &__rights {
                white-space: nowrap;
            }
        }

        @include down("md") {
            z-index: 3;
        }

        @include down("sm") {
            position: absolute;
            width: 95px;
            left: -95px;
            padding-bottom: 0;
            z-index: 1000;
        }
    }

    &--expanded &__sidebar {
        flex-basis: 280px;
        width: 280px;
        min-width: 280px;

        @include down("md") {
            //
        }

        @include down("sm") {
            width: 100%;
            flex-basis: 100%;
            left: 0;
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
    }

    &__content {
        padding: 50px 90px 30px calc(90px + 95px);
        max-width: calc(1200px + #{$sidebar-width});
        transition: padding-left 0.1s linear;
        color: var(--color) !important;

        @media (min-width: 1600px) {
            max-width: 100%;
        }

        @media (min-width: 2200px) {
            padding: 50px 90px 30px calc(90px + 95px);
            max-width: 100%;
        }

        @include down("md") {
            padding: 50px 40px 20px calc(40px + 95px);
        }

        @include down("sm") {
            padding: 80px 20px 20px 20px;
        }
    }

    &--expanded &__content {
        @include up("lg") {
            padding: 50px 90px 30px calc(90px + #{$sidebar-width});
        }
    }
}

.general_logo {
    width: 100%;
}

.social-icons{
    background-color: transparent;
}