@import 'src/theme';

.textarea {
  width: 100%;

  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    border-radius: 10px;
    align-items: center;
  }

  &__input {
    width: 100%;
    padding: 8px 8px;
    font-size: 16px;
    color: var(--color);
    border: 0.2rem solid var(--back-sidebar) !important;
    border-radius: 10px;
    background-color: var(--back-sidebar);

    &::placeholder {
      color: $color-muted;
    }

    &:focus-visible {
      outline: none;
      outline-offset: 0;
    }
  }

  &--focus &__input {
    box-shadow: rgb(255 95 21 / 25%) 0 0 0 0.2rem !important;
  }

  &--error &__input {
    border-color: $color-error !important;
  }

  &__helper-text {
    position: absolute;
    padding-top: 2px;
    padding-left: 8px;
  }

  &--error &__helper-text {
    color: $color-error !important;
  }
}
