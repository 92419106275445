.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.1s linear,
                opacity 0.1s linear;
    background-color: rgba(29, 29, 31, 0.3);
    opacity: 1;
    z-index: 10;

    &:not(&--open) {
        opacity: 0;
        pointer-events: none;
        background-color: transparent;
        z-index: -1;
    }
}
