
.container {
  margin-top: 3rem;
}
.card {
  box-shadow: none !important;
  background: transparent !important;
  display: flex;

  &__action {
    visibility: hidden;
  }


  &:first-child {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
  }

  &__area {
    display: flex !important;
    padding:  0.75rem 1rem  !important;

    &:hover {
      .card__action {
        visibility: visible;
      }
    }
  }

  &__video {
    border-radius: 10px !important;
    width: 120px !important;
    height: 67px !important;
    background: black;
  }
  &__content {
    * {
    letter-spacing: -0.4px !important;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.25rem !important;
    width: 100%;
  }

  &__description {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* количество строк */
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 330px;
    text-overflow: ellipsis;
  }

  &__actions {
    padding: 0 !important;
    margin-top: 1rem;
  }
  
  @media (max-width: 769px) {

    margin-bottom: 0.75rem !important;

    &:first-child {
      border-radius: 12px !important;
    }

    &__company {
      display: none;
    }
    &__action {
      display: none;
    }

    &__area {
      padding: 0 !important;
    }

    &__content {
      padding: 0 8px !important;
      height: 100% !important;
      align-items: start;
    }

    &__description {
      margin-top: 4px !important;
       p {
       font-size: 14px !important;
       }
    }

    &__title {
      p {
        font-size: 16px !important;
      }
    }

  }
}

.divider {
  height: 0 !important;

}

  @media (max-width: 769px) {
    .divider {
      display: none;
    }
    .day {
      margin-bottom: 8px !important;
        p {
         font-size: 16px !important;
        }
    }
    .container {
      margin-top: 1rem;
    }
  }
