.notAuthMessageContent {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}