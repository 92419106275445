.icon {
  display: inline-block;
  vertical-align: middle;

  &--background {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--mask {
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: currentColor;
  }

  &--size-mini {
    width: 10px;
    height: 10px;
  }

  &--size-small {
    width: 16px;
    height: 16px;
  }

  &--size-smallmed {
    width: 21px;
    height: 21px;
  }

  &--size-medium {
    width: 30px;
    height: 30px;
  }

  &--size-large {
    width: 42px;
    height: 42px;
  }

  &--size-extra {
    width: 60px;
    height: 60px;
  }

  &--color-green {
    color: #0aaf60;
  }

  &--color-gray {
    color: #999;
  }

  &--color-secondary {
    color: var(--color);
  }
}

@media (max-width: 500px) {
  .logo-icon {
    width: 130px;
  }
}