body[data-theme='light'] {
  --body-bg: #f0f1f7;
  --color: black;
  --back-sidebar: white;
  --main-content-bg: #f0f1f7;
  --back-form: white;
  --back-scroll: #d5d5d5;
  --opos-color: white;
  --grey-white: #212529;
  --back-btn: #aaaaaa;
  --player-background: #ecebeb;
  --disabled: #eaeaea;
}

body[data-theme='dark'] {
  --body-bg: rgb(29, 29, 31);
  --color: white;
  --back-sidebar: #231f20;
  --main-content-bg: rgb(29, 29, 31);
  --back-form: white;
  --back-scroll: #000;
  --opos-color: black;
  --grey-white: white;
  --back-btn: black;
  --player-background: #2d292a;
  --disabled: #313131;
}

html {
  height: 100%;
}

body {
  color: var(--grey-white) !important;
}

.bg-brown {
  background-color: var(--main-content-bg) !important;
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: var(--back-sidebar) !important;
}

.accordion__button,
.page-heading {
  color: var(--color) !important;
}

.icon-mode {
  background: var(--color) !important;
}

.text_underline {
  position: relative;
  cursor: pointer;
}

.text_underline:hover {
  opacity: 0.7;
}

.text_underline::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  background-color: rgb(255, 95, 21);
  bottom: -2px;
}

.MuiInputLabel-outlined {
  transform: translate(15px, 14px) scale(1) !important;
}

.ps__rail-y {
  background: inherit !important;
}

.modal-bg {
  background-color: white;
  padding: 10px;
  border: 2px solid orange;
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 999;
}

.spinner-container-login {
  width: 100%;
  height: 328px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customSlideCaptchaContainer .panel {
  display: block !important;
  position: relative;
  bottom: 0 !important;
}

.customSlideCaptchaContainer .slider {
  font-weight: bold;
  line-height: 28px !important;
  transition: none;
}

.customSlideCaptchaContainer .slider::before {
  display: none;
}

@media (max-width: 380px) {
  .react-select__value-container {
    padding-right: 0 !important;
    padding-left: 5px !important;
  }
}

/* for big screens  */

@media (min-width: 2200px) {
  .page-header__title {
    font-size: 1.5em;
  }

  .page-header__subtitle {
    font-size: 1.25em;
  }

  .dashboard-layout__content {
    margin: 0 auto;
  }

  .video--title {
    font-size: 2.5rem !important;
  }

  .video--description {
    font-size: 1.7rem !important;
  }

  .category-item__title {
    font-size: 1.6rem;
    min-height: 100px;
  }

  .container_wartext p {
    font-size: 1.1rem !important;
  }

  .history-title {
    font-size: 1.5rem;
  }

  .history-name {
    font-size: 1rem;
  }

  .balance__title,
  .balance__text,
  .help--text {
    font-size: 1.5rem;
  }

  .userName {
    font-size: 1.5rem;
  }

  .userPhone {
    font-size: 1rem;
    font-weight: 500;
  }

  .MuiAvatar-circular,
  .setting-container,
  .reminder-container {
    margin: 0 5px;
    width: 60px !important;
    height: 60px !important;
  }

  .page-header__title {
    font-size: 2rem;
  }

  .category-item {
    min-height: 220px;
    height: auto;
  }

  .category-item__icon-wrapper {
    width: 200px;
    min-height: 220px;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  appearance: none;
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer !important;
}

.hover-opacity:hover {
 opacity: 0.9;
}